html, body, #root {
  height: 100%
}
.MuiInputBase-input:disabled {
  color: darkgrey;
}

form.login {
  width: 350px;
  margin-top: 75px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}
form.login .MuiFormControl-root {
  width: 100%;
  margin-bottom: .25rem;
}
form.login button {
  margin-top: 1rem;
}

th.consistencyTest {
  min-width: 10rem;
}
th.pressureStrength {
  min-width: 10rem;
}

table.customerView .consistencyTest,
table.customerView .temperature,
table.customerView .wcf,
table.customerView .weight
{
  display: none;
}
